@import "../../../../../../../theme/colors";
@import "../../../../../../../theme/responsive_mixin";

.CustomerCompanyUpdateAddressViewForm1 {
  background-color: $white1;
  padding: 5rem 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include mobile {
    padding: 5rem 20px;
  }
}

.CustomerCompanyUpdateAddressViewForm1Form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  section {
    padding: 20px 40px;
  }

  @include mobile {
    height: auto;

    section {
      padding: 0 20px;
    }
  }

  @include tablet {
    height: auto;

    section {
      padding: 0 20px;
    }
  }
}

.CustomerCompanyUpdateAddressViewForm1FormContentBtn{
  width: 100%;
  text-align: center;
}

.CustomerCompanyUpdateAddressViewForm1FormBtn {
  background-color: $primary;
  color: $white1;
  border-radius: 10px;
  border: none;
  padding: 10px 30px;
  margin: 10px;
  font-size: 1rem;
  transition: 400ms;
}

.CustomerCompanyUpdateAddressViewForm1FormBtn:hover {
  background-color: $dark;
  cursor: pointer;
}

.CustomerCompanyUpdateAddressViewForm1FormBtn:disabled {
  background-color: $black5;
  cursor: default;
}
