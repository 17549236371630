@import "../../theme/colors";

.ButtonContent {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 10px;
  margin-right: 10px;
}

%Button {
  border-radius: 20rem;
  padding: 10px 30px;
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 !important;

  .ButtonIcon{
    min-width: 20px !important;
    width: 20px !important;
    margin: 0 !important;
  }

  .ButtonChild{
    min-width: auto !important;
    text-transform: uppercase;
    margin: 0 !important;
    padding: 0 10px;
  }
}

.Button {
  @extend %Button;
  background-color: $white2 !important;
  opacity: 1;
  box-shadow: 0 2px 10px rgba($white5, .5);
}

.Button:hover {
  background-color: $white3;
  box-shadow: 0 2px 3px rgba($white5, .5);
  cursor: pointer !important;
  label {
    cursor: pointer !important;
  }
}

.ButtonDisabled{
  @extend %Button;
  opacity: .5;
  background-color: $white4;
  color: $black4 !important;
  box-shadow: inset 0 2px 10px rgba($black4, .5) !important;
}

.ButtonPrimary {
  @extend %Button;
  background-color: $primary !important;
  color: $white1 !important;
  box-shadow: 0 2px 10px rgba($white5, .5);
  opacity: 1;
}

.ButtonPrimary:hover {
  background-color: $dark !important;
  box-shadow: 0 2px 3px rgba($white5, .5);
  cursor: pointer !important;
  label {
    cursor: pointer !important;
  }
}
