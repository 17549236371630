@import "../../../../../theme/colors";
@import "../../../../../theme/responsive_mixin";

.CustomerInvoiceViewBanner1 {
  background-color: $white3;
  padding: 3rem 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    text-align: center;
  }

  p {
    text-align: center;
    color: $black3;
  }

  @include mobile {
    padding: 3rem 20px;
  }
}

.CustomerInvoiceViewBanner1Buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  background-color: $white2;
  border-radius: 10px;
  box-shadow: inset 0 2px 5px rgba($black5, .5);

  @include mobile {
    flex-direction: column;
  }
}

.CustomerInvoiceViewBanner1ButtonsLink {
  padding: 10px 15px;
  color: $primary;
  transition: 400ms;
  border-right: 1px solid $white4;
  text-align: center;
  font-weight: bold;

  @include mobile {
    padding: 15px 10px;
    border-right: none;
    border-bottom: 1px solid $white4;
  }
}

.CustomerInvoiceViewBanner1ButtonsLink:hover {
  color: $dark;
}


.CustomerInvoiceViewBanner1ButtonsLink:last-child {
  border-right: none;
  @include mobile {
    border-bottom: none;
  }
}
