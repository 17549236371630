@import "../../../../../theme/colors";
@import "../../../../../theme/responsive_mixin";

.CustomerAppsSection1 {
  padding: 5rem 10rem;
  background-color: $white1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include mobile {
    padding: 5rem 20px;
  }
}

.CustomerAppsSection1Items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @include mobile {
    flex-direction: column;
  }
}

.CustomerAppsSection1Item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px 15px;
  padding: 10px 20px;
  background-color: $white2;
  border: none;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba($black5, .5);
  transition: 400ms;
  text-decoration: none;
}

.CustomerAppsSection1Item:hover {
  box-shadow: inset 0 2px 5px rgba($black5, .5);
  cursor: pointer;

  label {
    cursor: pointer;
  }

  .CustomerAppsSection1ItemTitle {
    color: $primary;
  }
}

.CustomerAppsSection1ItemImg {
  min-width: 10rem;
  width: 10rem;
  min-height: 10rem;
  height: 10rem;
  object-fit: cover;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}

.CustomerAppsSection1ItemTitle {
  font-weight: bold;
  font-size: 1.2rem;
  transition: 400ms;
  margin: 5px;
  color: $black1;
}

.CustomerAppsSection1ItemPackage{
  font-weight: 300;
  font-size: 1rem;
  color: $black3;
}

.CustomerAppsSection1ItemDescription{
  font-weight: 400;
  font-size: 1rem;
  color: $black4;
  background-color: $white1;
  padding: 10px 15px;
  border-radius: 20px;
  box-shadow: inset 0 1px 2px rgba($black5, .5);
  text-align: center;
}
