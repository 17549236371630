@import "../../theme/colors";
@import "../../theme/responsive_mixin";

.Footer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  background-color: $primary;

  @include mobile {
  }
}

.FooterLinks {
  width: 100%;
  background-color: $white2;
  padding: 2rem 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @include mobile {
    padding: 3rem 20px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.FooterLinksConcept {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 250px;

  img {
    width: 15rem;
  }

  p {
    margin: 0;
    padding: 10px 10px;
    font-weight: 300;
    font-size: 1rem;
    color: $black3;
    text-align: left;

    i {
      font-size: 12px;
    }
  }

  @include mobile {
    flex: 1;
    width: 88%;

    p {
      font-size: 1rem;
      margin-top: 10px;
      padding: 0;
    }
  }
}

.FooterLinksNavBar {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 20px;

  section {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0 20px
  }

  label {
    padding: 15px 10px;
    font-weight: 300;
    font-size: 1rem;
    color: $black3;
  }

  @include mobile {
    padding: 0;
    flex-direction: column;
    justify-content: center;
    width: 88%;

    section {
      padding: 0;
      text-align: center;
    }
  }
}

.FooterLinksNavBarLink {
  padding: 15px 10px;
  cursor: pointer;
  text-decoration: none;

  label {
    font-weight: bold !important;
    font-size: 1rem;
    color: $black3;
    transition: 500ms;
    padding: 0;
    text-transform: capitalize;
  }
}

.FooterLinksNavBarLink:hover {
  label {
    color: $primary;
    cursor: pointer;
  }
}

.FooterLinksNavBarLinkFocus {
  padding: 15px 10px;
  cursor: pointer;
  transition: 500ms;
  text-decoration: none;

  label {
    font-weight: bold !important;
    font-size: 1rem;
    cursor: pointer;
    color: $primary;
    padding: 0;
  }
}

.FooterCopyright {
  width: 100%;
  background-color: $dark;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  color: $white1;
  font-size: 12px;
  padding: 2rem 5rem;

  span {
    margin: 0 15px;
    font-weight: bold;
    cursor: pointer;
    opacity: .8;
    transition: 300ms;
  }

  span:hover {
    opacity: 1;
  }

  @include mobile {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 3rem 20px;

    span {
      margin: 10px 5px;
      font-size: 1rem;
    }
  }
}

.FooterCopyrightText {
  margin-right: 20px;

  p {
    font-size: 13px;
    font-weight: 300;
    margin: 0;
    margin-bottom: -1px;
  }

  label {
    font-size: 10px;
    font-weight: 300;
  }

  @include mobile {
    margin-right: 0;
    margin-top: 20px;

    p {
      font-size: 1.2rem;
    }

    label {
      font-size: 1rem !important;
    }
  }
}

.FooterWs {
  position: absolute;
  z-index: 1000;
  top: auto;
  right: 100px;
  bottom: 22px;
  background-color: green;
  color: #fff;
  border: none;
  border-radius: 20rem;
  font-size: 2.5rem;
  min-width: 60px;
  width: 60px;
  max-width: 60px;
  min-height: 60px;
  height: 60px;
  max-height: 60px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, .15);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  margin: 0 !important;
}

