@import "../../../../../theme/colors";
@import "../../../../../theme/responsive_mixin";

.IndexRegisterForm1 {
  padding: 5rem 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $white1;

  @include mobile {
    padding: 5rem 20px;
  }
}

.IndexRegisterForm1Title {
  margin: 0;
}

.IndexRegisterForm1Subtitle {
  font-size: 1.2rem;
  font-weight: 300;
  color: $black3;
  text-align: center;
}

.IndexRegisterForm1Form {
  display: flex;
  flex-direction: column;
  width: 40vw;

  @include mobile {
    width: 90vw;
  }

  @include tablet {
    width: 70vw;
  }
}


.IndexRegisterForm1FormIpt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  label {
    width: 100%;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    color: $black2;
    font-size: 1rem;
    padding: 10px;
  }

  input {
    width: 60%;
    font-weight: 300;
    font-size: 1rem;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid $white5;
  }

  select {
    width: 65%;
    font-weight: 300;
    font-size: 1rem;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid $white5;
  }

  @include mobile {
    label {
      flex-direction: column;
      align-items: inherit;
      margin-bottom: 10px;
    }

    input {
      width: auto;
      margin-top: 10px;
    }

  }
}

.IndexRegisterForm1FormIptError{
  margin-left: 35%;
  font-weight: 400;
  font-size: 12px;
  color: darkred;
  padding: 10px 0;

  @include mobile {
    margin-left: 0;
  }
}

.IndexRegisterForm1FormIptBtn {
  background-color: $primary;
  color: $white1;
  border-radius: 10px;
  border: none;
  padding: 10px 30px;
  margin: 10px;
  font-size: 1rem;
  transition: 400ms;
}

.IndexRegisterForm1FormIptBtn:hover {
  background-color: $dark;
  cursor: pointer;
}

.IndexRegisterForm1FormIptBtn:disabled {
  background-color: $black5;
  cursor: default;
}

.IndexRegisterForm1FormIptLogin{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 20px;

  label {
    font-weight: 300;
    color: $black3;
  }

  a {
    font-weight: bold;
    color: $black1;
    transition: 400ms;
    margin-left: 10px;
  }

  a:hover {
    color: $primary;
  }
}
