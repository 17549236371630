@import "../../../theme/colors";
@import "../../../theme/responsive_mixin";

.CustomerNavBarMenu {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $white1;
  padding: 10px 10rem;
  box-shadow: 0 2px 10px rgba($black5, .5);
  position: sticky;
  top: 0;
  z-index: 999 !important;
  transition: 500ms;
  margin-top: -20px;

  @include mobile {
    justify-content: space-between !important;
    padding: 10px 20px;
  }

  @include tablet {
    padding: 10px 20px;
  }
}

.CustomerNavBarMenuSecLogo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 5rem;
  -webkit-user-drag: none;
  user-select: none;
  opacity: .9;
  transition: 300ms;
  position: relative;

  img {
    width: 50%;
    height: 100%;
  }

  section {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    justify-content: center;
    align-items: flex-start;

    label {
      color: $primary;
    }

    label:hover {
      cursor: pointer;
    }

    label:nth-child(1) {
      font-size: 1rem;
      top: 10px;
      left: 4.3rem;
      margin-top: 5px;
      margin-bottom: -2px;
    }

    label:nth-child(2) {
      font-weight: bold;
      font-size: 1.5rem;
      top: 10px;
      left: 4.3rem;
    }
  }

  @include mobile {
    img {
      width: 10rem;
    }
  }
}

.CustomerNavBarMenuSecLogo:hover {
  opacity: 1;
  cursor: pointer;
}

.CustomerNavBarMenuSecMenuBtn {
  display: none;
  font-size: 2rem;
  width: 4rem;
  height: 4rem;
  color: $primary;
  border-radius: 20rem;
  box-shadow: 0 2px 10px rgba($white5, .5);

  @include mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @include tablet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.CustomerNavBarMenuSecMenuBtn:hover {
  animation: fadeIn 1s;
}

.CustomerNavBarMenuSecMenu {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.CustomerNavBarMenuSecMenuResponsive {
  position: absolute;
  z-index: 0 !important;
  background-color: $white1;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.CustomerNavBarMenuSecMenuResponsive::before {
  position: absolute;
  z-index: -1 !important;
  background-color: rgba($black2, .8);
  width: 100vw;
  height: 100vh;
  content: "";
  opacity: .5;
  transition: 400ms;
  animation: fadeIn .5s both;
}

.CustomerNavBarMenuSecMenuItem {
  margin: 15px 15px;
  border: none;
  font-weight: bold;
  font-size: 1rem;
  color: $black1;
  position: relative;
  z-index: 1;
  transition: 400ms;

  @include mobile {
    margin: 0;
    padding: 15px 15px;
  }

  @include tablet {
    margin: 0;
    padding: 15px 15px;
  }
}

.CustomerNavBarMenuSecMenuItem:last-child {

  @include mobile {
    padding-bottom: 30px;
  }

  @include tablet {
    padding-bottom: 30px;
  }
}

.CustomerNavBarMenuSecMenuItem::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1 !important;
  background-color: $white1;
  width: 100%;
  height: 100%;
  content: "";
}


.CustomerNavBarMenuSecMenuItem:hover {
  cursor: pointer;
  color: $primary;

  label {
    cursor: pointer;
  }
}

.CustomerNavBarMenuSecMenuItemFocus {
  margin: 15px 15px;
  padding: 10px 15px;
  background-color: $white1;
  color: $primary;
  border: none;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 10px;
  transition: 400ms;

  label {
    cursor: pointer;
  }

  @include mobile {
    margin: 0;
    padding: 15px 15px;
    border-radius: 0;
  }

  @include tablet {
    margin: 0;
    padding: 15px 15px;
    border-radius: 0;
  }
}

.CustomerNavBarMenuSecMenuItemFocus::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1 !important;
  background-color: $white1;
  width: 100%;
  height: 100%;
  content: "";
}

.CustomerNavBarMenuSecMenuItemFocus:hover {
  cursor: pointer;
}

.CustomerNavBarMenuSecMenuItemPromo {
  margin: 15px 15px;
  padding: 10px 15px;
  background: $primary;
  color: $white1;
  border: none;
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
  border-radius: 10px;

  label {
    cursor: pointer;
  }

  @include mobile {
    border-radius: 0;
  }
}

.CustomerNavBarMenuSecMenuItemPromo:hover {
  animation: fadeIn 1s;
  cursor: pointer;
}
