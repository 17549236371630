@import "../../../../../../theme/colors";
@import "../../../../../../theme/responsive_mixin";

.CustomerSupportTrackingViewSection1 {
  background-color: $white1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include mobile {
  }
}

.CustomerSupportTrackingViewSection1Items {
  width: 60%;
  border: 1px solid $white3;
  background-color: $white2;

  @include mobile {
    width: 100%;
    border: none;
  }

  @include tablet {
    width: 100%;
    border: none;
  }
}

.CustomerSupportTrackingViewSection1Item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 10px 15px;
  padding: 10px 20px 0;
  background-color: $white2;
  border: none;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba($black5, .5);
  transition: 400ms;
  text-decoration: none;
}

%CustomerSupportTrackingViewSection1ListScreenBodyItem {
  margin: 10px 10px;
  background-color: $white1;
  padding: 15px 10px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba($white5, .5);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  div {
    margin: 0 10px 0 10px;
    display: flex;
    flex-direction: column;
    max-width: 150px;

    label:first-child {
      font-weight: bold;
      text-transform: uppercase;
    }

    label:nth-child(2) {
      font-weight: 400;
      font-size: 14px;
      text-transform: lowercase;
    }

    label:nth-child(3) {
      font-weight: 300;
      font-size: 12px;
    }

    label {
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.CustomerSupportTrackingViewSection1ListScreenBodyItem:hover {
  cursor: pointer !important;
  box-shadow: 5px 10px 10px rgba($black5, .5);

  label {
    cursor: pointer !important;
  }
}

.CustomerSupportTrackingViewSection1ListScreenBodyItemActive {
  @extend %CustomerSupportTrackingViewSection1ListScreenBodyItem;
  box-shadow: 5px 10px 10px rgba($black5, .5);
  border: 3px solid rgba($dark, .5);
  padding-top: -5px;

  p {
    color: $black1 !important;
  }
}

.CustomerSupportTrackingViewSection1Footer {
  position: sticky;
  width: 100%;
  bottom: 0;
  z-index: 110;
  padding: 10px 0 30px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: rgba($white3, .8);
}

.CustomerSupportTrackingViewSection1FooterInput {
  flex: 1;
  border: 1px solid $white4;
  border-radius: 20rem;
  padding: 10px 15px;
  margin: 10px;

  @include mobile {
    width: 100%;
  }
}

.CustomerSupportTrackingViewSection1FooterButton {
  margin: 0 5px;
  width: 40px;
  height: 40px;
  background: $primary;
  padding: 10px;
  border-radius: 20rem;
  border: none;
  font-size: 1rem;
  cursor: pointer;
}

.CustomerSupportTrackingViewSection1FooterFile {
  position: absolute;
  z-index: -1;
  left: 0;
  transform: translateY(-5.6rem);
  background: rgba($white3, .8);
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  img {
    width: auto;
    height: 5rem;
  }
}

.CustomerSupportTrackingViewSection1FooterFileClose {
  position: absolute;
  top: -5px;
  right: -10px;
  background-color: $primary;
  border-radius: 20rem;
  border: none;
  color: rgba($white1, .8);;
  font-size: 1rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 5px rgba($black5, .5);
  cursor: pointer;
  transition: 400ms;
}

.CustomerSupportTrackingViewSection1FooterFileClose:hover {
  color: $white1;
}
