@import "../../../../../../../theme/colors";
@import "../../../../../../../theme/responsive_mixin";

.CustomerCompanyUploadLogoViewFile1 {
  padding: 5rem 10rem;
  background: $white1;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.CustomerCompanyUploadLogoViewFile1Icon {
  margin: 20px 10px;
  background-color: $white2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 10rem;
  border-radius: 20rem;
  overflow: hidden;
  font-size: 5rem;
}

%CustomerCompanyUploadLogoViewFile1IconImageCircle {
  margin: 20px 10px;
  background-color: $white2;
  border-color: $primary;
  border-width: 0.5rem;
  border-style: solid;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.CustomerCompanyUploadLogoViewFile1IconImageCircle {
  @extend %CustomerCompanyUploadLogoViewFile1IconImageCircle;
  border-radius: 20rem;
  width: 10rem;
  height: 10rem;
}

.CustomerCompanyUploadLogoViewFile1IconImageRec {
  @extend %CustomerCompanyUploadLogoViewFile1IconImageCircle;
  border-radius: 20px;
  width: 20rem;
  height: 10rem;
}

.CustomerCompanyUploadLogoViewFile1ImgButton {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 3rem;
  height: 3rem;
  background-color: $primary;
  color: $white1;
  border-radius: 20rem;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 10px rgba($black5, .5);

  label {
    padding: 0;
    margin-top: 0;
    margin-left: 0;
  }

  @include mobile {
    label {
      padding: 0;
    }
  }
}

.CustomerCompanyUploadLogoViewFile1ImgButton:hover {
  background-color: $dark;
  cursor: pointer;

  label {
    cursor: pointer;
  }
}

.CustomerCompanyUploadLogoViewFile1PhotoProfile {
  width: 15rem;
  height: 15rem;
  border-radius: 20rem;
  resize-mode: cover;
}

.CustomerCompanyUploadLogoViewFile1TitleContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;

  @include tablet {
    align-items: center;
  }
  @include desktop {
    align-items: center;
  }

  legend {
    font-weight: bold;
    text-transform: uppercase;
  }

  p {
    margin: 0;
    color: $black4;
  }
}

.CustomerCompanyUploadLogoViewFile1ButtonContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @include tablet {

  }
  @include desktop {

  }
}
