@import "../../../../../theme/colors";
@import "../../../../../theme/responsive_mixin";

.CustomerHomeSection1Content {
  padding: 8rem 10rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: $white3;
  min-height: 70vh;

  @include mobile {
    padding: 8rem 20px;
  }
}

.CustomerHomeSection1 {
  background-color: $white1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba($black5, .5);
}

.CustomerHomeSection1CustomerLogo {
  width: 10rem;
  height: 10rem;
  border: 5px solid $primary;
  border-radius: 20rem;
  overflow: hidden;
  margin-top: -5rem;
  object-fit: cover;
  box-shadow: 0 2px 5px rgba($black5, .5);
  transition: 500ms;

  img {
    transition: 500ms;
    width: 100%;
    height: 100%;
    background-color: $white3;
  }
}
