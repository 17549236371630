@import "../../../theme/colors";

.IndexAppAdminGroup {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: $white3;
  overflow-x: hidden;
  overflow-y: auto;
}
