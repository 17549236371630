@import "../../../../../theme/colors";
@import "../../../../../theme/responsive_mixin";

.IndexAboutSection3 {
  padding: 10rem 10rem 5rem;
  background-color: $white1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;

  @include mobile {
    padding: 5rem 0 0;
    flex-wrap: wrap;
  }

  @include tablet {
    flex-wrap: wrap;
  }
}

.IndexAboutSection3Group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 300px;
  height: 80%;
  border: 1px solid $white4;
  border-radius: 20px;
  margin: 20px;
  padding: 20px 20px;

  @include mobile{
    padding: 20px 10px;
    height: auto;
  }
}

.IndexAboutSection3Background {
  z-index: 1;
  min-width: 15rem;
  width: 15rem;
  min-height: 15rem;
  height: 15rem;
  transition: 500ms;
  overflow: hidden;
  border-radius: 20rem;
  transform: translateY(-4rem);
  box-shadow: 0 2px 10px rgba($black4, .5);
  object-fit: cover;

  .IndexAboutSection3BackgroundImg {
    width: 100%;
    height: 100%;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    filter: grayscale(100%);
    position: relative;
    z-index: 20;
  }

  @include mobile {
    width: 15rem;
    height: 15rem;
    transform: translateX(-1px);

    .IndexAboutSectionBackgroundImg {
      background-position: -5rem 0;
      background-size: cover;
    }
  }
}

.IndexAboutSection3Background::after {
  content: "";
  background-color: rgba($dark, .5);
  position: absolute;
  z-index: 1;
  width: 11rem;
  height: 11rem;
  border-radius: 20rem;
  transform: translateX(-1rem) translateY(-9rem);

  @include mobile {
    width: 15rem;
    height: 15rem;
    transform: translateX(-1rem) translateY(-17rem);
  }
}

.IndexAboutSection3Background::before {
  content: "";
  background-color: rgba($primary, .5);
  position: absolute;
  z-index: 2;
  width: 11rem;
  height: 11rem;
  border-radius: 20rem;
  transform: translateX(0rem) translateY(-1rem);

  @include mobile {
    width: 15rem;
    height: 15rem;
    transform: translateX(-2rem) translateY(1rem);
  }
}

.IndexAboutSection3Sec {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translateY(-1rem);

  h1 {
    margin: 10px 0 0;
    color: $primary;
    text-align: center;
    font-weight: bold;
  }

  h4 {
    margin: 10px 0 0;
    font-size: 1rem;
    font-weight: 300;
    color: $black4;
  }

  p {
    font-weight: 400;
    color: $black2;
    line-height: 1.5rem;
    text-align: center;
  }

  @include mobile {
    width: 80%;

    h1 {
      margin-top: 3rem;
      font-size: 2rem;
    }

    h4 {
      font-size: 1.2rem;
      text-align: center;
    }

    p {
      font-size: 1rem;
      text-align: center;
    }
  }

  @include tablet {
    transform: translateX(0);
    width: 100vw;
    padding-left: 30px;

    h1 {
      margin-top: 3rem;
    }
  }
}
