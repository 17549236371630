@import "../../../../../theme/colors";
@import "../../../../../theme/responsive_mixin";

.IndexFaqBanner1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16rem 10rem 0;
  margin: 0 0 -5rem;
  background-color: $white1;
  position: relative;

  @include mobile {
    padding: 18rem 20px 0;
    margin: 0 0 -2rem;
  }
}

.IndexFaqBanner1Title {
  position: absolute;
  top: -10px;
  z-index: 2;
  text-align: center;
  color: $white1;
  background-color: $dark;
  margin: 0;
  padding: 1.5rem 10rem;
  width: 100%;
  transform: skewY(-5deg);

  h1 {
    transform: skewY(5deg);
    background-color: $primary;
    padding: 20px;
    font-weight: bold;
  }

  p {
    transform: skewY(5deg);
    background-color: $primary;
    padding: 20px;
    margin-top: -35px;
    font-weight: 300;
  }

  @include mobile {
    padding: 2rem 20px;
    transform: skewY(-10deg);

    h1, p {
      transform: skewY(10deg);
    }
  }
}

.IndexFaqBanner1Title::before {
  position: absolute;
  width: 100%;
  left: 0;
  transform: skewY(10deg);
  padding: 5rem;
  background-color: rgba($dark, .8);
  content: "";

  @include mobile {
    background-color: rgba($dark, .8);
    transform: skewY(20deg);
  }
}
