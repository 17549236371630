@import "../../theme/colors";
@import "../../theme/responsive_mixin";

.Image {
  position: relative;
}

.ImageBlur {
  filter: blur(10px);
}

.ImageProtect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}
