@import "../../../../../../theme/colors";
@import "../../../../../../theme/responsive_mixin";

.CustomerSupportTrackingUpdateViewBanner1 {
  background-color: $white3;
  padding: 3rem 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    text-align: center;
    margin: 0 0 10px;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      text-transform: uppercase;
      font-weight: 300;
      white-space: nowrap;
    }
  }

  p {
    text-align: center;
    color: $black3;
  }

  @include mobile {
    padding: 3rem 20px;
  }
}

.CustomerSupportTrackingUpdateViewBanner1LinkContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @include mobile {
    flex-direction: column;
  }
}

.CustomerSupportTrackingUpdateViewBanner1Link {
  padding: 10px 5px;
  color: $black3;
  transition: 400ms;
  border-right: 1px solid $white4;
  text-align: center;
  font-weight: bold;

  @include mobile {
    padding: 15px 5px;
    border-right: none;
    border-bottom: 1px solid $white4;
  }
}

.CustomerSupportTrackingUpdateViewBanner1Link:hover {
  color: $primary;
}


.CustomerSupportTrackingUpdateViewBanner1Link:last-child {
  border-right: none;
  @include mobile {
    border-bottom: none;
  }
}
