@import "../../../../../theme/colors";
@import "../../../../../theme/responsive_mixin";

.IndexAppAdminSection10Content {
  padding: 5rem 10rem;
  background-color: rgba($primary, .1);

  @include mobile {
    padding: 5rem 20px;
  }
}

.IndexAppAdminSection10 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @include mobile {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

  }
}

.IndexAppAdminSection10Img {
  width: 30rem;
  padding-right: 20px;

  img {
    width: 100%;
  }

  @include mobile {
    width: 100%;

    img {
      width: 100%;
      background-size: cover;
      object-fit: cover;
    }
  }
}

.IndexAppAdminSection10Sec {
  flex: 1;

  h1 {
    font-weight: 400;
    font-size: 2.5rem;
  }

  p {
    font-weight: 300;
    font-size: 1.5rem;
    color: $black3;
  }

  button {
    background-color: $primary;
    color: $white1;
    font-size: 1rem;
    padding: 15px 40px;
    border: none;
    transition: 300ms;
    border-radius: 5px;
  }

  button:hover {
    background-color: $dark;
    cursor: pointer;

    label {
      cursor: pointer;
    }
  }

  @include mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;

    h1 {
      width: 90%;
      text-align: center;
      word-wrap: break-word;
      margin-left: -10px;
      margin-bottom: 0px;
    }

    p {
      text-align: center;
      margin-left: -20px;
    }
  }
}
