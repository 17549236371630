@import "../../../../../theme/colors";
@import "../../../../../theme/responsive_mixin";

.MenuAppContainer {
  background-color: $white1;
  padding: 0 10rem;

  @include mobile {
    padding: 0;
  }
}

.MenuAppContainerMobile {
  display: none;

  @include mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0 40px;
  }
}

.MenuAppContainerDesktop {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;

  @include mobile {
    display: none;
  }
}

.MenuAppContainerDesktopSnapContent {
  display: flex;
  flex-direction: row;
  min-width: 80vw;
  min-height: 50vh;
  overflow-x: hidden;
  overflow-y: hidden;
  transition-delay: 3s;
}


.MenuAppContainerDesktopSec {
  position: relative;
  z-index: 1;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 1000ms;
  scroll-snap-align: start;
}


.MenuAppContainerDesktopBtn {
  position: relative;
  z-index: 2;
  background-color: $white3;
  padding: 20px;
  border: none;
  border-radius: 10px;
  transition: 300ms;
}

.MenuAppContainerDesktopBtn:hover {
  background-color: $primary !important;
  color: $white1;
  cursor: pointer;

}
