@import "../../../../../theme/colors";
@import "../../../../../theme/responsive_mixin";

.CustomerLoginForm1 {
  padding: 5rem 10rem;
  background: linear-gradient(to bottom, rgba($primary, .2), rgba($dark, .3)), white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include mobile {
    padding: 5rem 20px;
  }
}

.CustomerLoginForm1Group {
  background-color: $white1;
  padding: 30px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40vw;
}

.CustomerLoginForm1Title {
  margin: 10px 0 0;
  color: $black1;
}

.CustomerLoginForm1Subtitle {
  margin: 0 0 10px;
  color: $black3;
}

.CustomerLoginForm1GroupForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $white1;
  padding: 20px 30px;
  border-radius: 10px;

  @include mobile {
    width: 90vw;
  }

  @include tablet {
    width: 70vw;
  }
}

.CustomerLoginForm1GroupFormImg {
  width: 8rem;
}

.CustomerLoginForm1GroupFormIpt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  input {
    font-weight: 300;
    font-size: 1rem;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid $white5;
    margin: 10px;
  }

  select {
    width: 65%;
    font-weight: 300;
    font-size: 1rem;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid $white5;
  }

  @include mobile {
    label {
      flex-direction: column;
      align-items: inherit;
      margin-bottom: 10px;
    }

    input {
      width: auto;
      margin-top: 10px;
    }

  }
}

.CustomerLoginForm1GroupFormIptError {
  font-weight: 400;
  font-size: 12px;
  color: darkred;
  padding: 10px 0;
  margin-top: -10px;

  @include mobile {
    margin-left: 0;
  }
}

.CustomerLoginForm1GroupFormBtn {
  background-color: $primary;
  color: $white1;
  border-radius: 10px;
  border: none;
  padding: 10px 30px;
  margin: 10px;
  font-size: 1rem;
  transition: 400ms;
}

.CustomerLoginForm1GroupFormBtn:hover {
  background-color: $dark;
  cursor: pointer;
}

.CustomerLoginForm1GroupFormBtn:disabled {
  background-color: $black5;
  cursor: default;
}

.CustomerLoginForm1GroupFormFooter {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
  font-size: .8rem;

  label {
    font-weight: 300;
    color: $black3;
  }

  a {
    font-weight: bold;
    color: $black1;
    transition: 400ms;
    margin-left: 5px;
  }

  a:hover {
    color: $primary;
  }
}
