@import "../../../../../theme/colors";
@import "../../../../../theme/responsive_mixin";

.IndexPriceSection1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 10rem 5rem;
  margin: 0;
  position: relative;
  background-color: $white1;

  @include mobile {
    padding: 5rem 20px;
  }
}


.IndexPriceSection1Group1 {
  position: relative;
  z-index: 2;
  margin: 10px 10px 0;
  padding: 20px 20px;
  background-color: $dark;
  width: 40vw;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba($black4, .5);
  color: $white1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .IndexPriceSection1SecTitle {
    border-bottom: 1px solid rgba($white1, .5);
  }

  @include mobile {
    width: 80vw;
  }
}

.IndexPriceSection1Group2 {
  margin: -15px 10px;
  padding: 35px 20px 20px;
  background-color: $black2;
  width: 30vw;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba($black4, .5);
  color: $white1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .IndexPriceSection1SecTitle {
    border-bottom: 1px solid rgba($white4, .5);
  }

  @include mobile {
    width: 70vw;
  }
}


.IndexPriceSection1SecTitle {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  font-size: 2rem;
  padding: 0 0 15px;

  span {
    border-radius: 20rem;
    width: 2rem;
    height: 2rem;
    background: rgba($white1, .5);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 10px;
  }

  legend {
    font-weight: bold;
  }

  @include mobile {
    font-size: 1.2rem;

    span {
      padding: 5px;
    }
  }
}


.IndexPriceSection1SecPrice {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  label {
    font-weight: bold;
    font-size: 2rem;
  }

  span {
    font-size: 1rem;
    font-weight: 300;
  }
}

.IndexPriceSection1SecDescription {

  label {
    font-weight: 300;
    margin-bottom: 20px;
  }

  ul {
    margin-top: 10px;
    padding: 0 25px;
    font-size: 1rem;
    font-weight: 300;
  }

  @include mobile {
    ul {
      padding: 0 15px;
    }
  }
}
