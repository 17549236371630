@import "../../../../../theme/colors";
@import "../../../../../theme/responsive_mixin";

.CustomerSupportSection1 {
  padding: 3rem 10rem;
  background-color: $white1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include mobile {
    padding: 3rem 20px;
  }
}

.CustomerSupportSection1Items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @include mobile {
    flex-direction: column;
  }
}

.CustomerSupportSection1ItemBadge {
  background-color: red !important;
  font-size: 1rem !important;
  padding: 15px !important;
  border-radius: 20rem !important;
  min-width: 2rem;
  width: 2rem;
  min-height: 2rem;
  height: 2rem;
}

.CustomerSupportSection1Item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
  padding: 10px 20px;
  background-color: $white2;
  border: none;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba($black5, .5);
  transition: 400ms;
  text-decoration: none;
}

.CustomerSupportSection1Item:hover {
  box-shadow: inset 0 2px 5px rgba($black5, .5);
  cursor: pointer;

  label {
    cursor: pointer;
  }

  .CustomerSupportSection1ItemTitle {
    color: $primary;
  }
}

.CustomerSupportSection1ItemImg {
  min-width: 10rem;
  width: 10rem;
  min-height: 10rem;
  height: 10rem;
  object-fit: cover;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}

.CustomerSupportSection1ItemTitle {
  font-weight: bold;
  font-size: 1.2rem;
  transition: 400ms;
  margin: 5px;
  color: $black1;
  text-align: center;
}

.CustomerSupportSection1ItemPackage {
  font-weight: 300;
  font-size: 1rem;
  color: $black3;
}

.CustomerSupportSection1ItemDescription {
  font-weight: 400;
  font-size: 1rem;
  color: $black4;
  background-color: $white1;
  padding: 10px 15px;
  border-radius: 20px;
  box-shadow: inset 0 1px 2px rgba($black5, .5);
  text-align: center;
}
