@import "../../theme/colors";
@import "../../theme/responsive_mixin";

.NavBarTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $primary;
  padding: 0 10rem;
  margin-top: -20px;
  transition: 500ms;
  position: relative;
  z-index: 1000 !important;

  @include mobile {
    justify-content: center !important;
    padding: 0 10px;

    .NavBarTopSecSocial {
      display: none;
    }
  }

  @include tablet {
    padding: 0 20px;
  }
}

.NavBarTopSocialBtn {
  background: none;
  border: none;
  border-right: .5px solid rgba($white1, .3);
  font-size: 1.5rem;
  padding: 10px 15px;
  color: $white1;
}

.NavBarTopSocialBtn:last-child {
  border-right: none;
}

.NavBarTopSocialBtn:hover {
  animation: fadeIn 1s;
  cursor: pointer;
}

.NavBarTopSecSession {
  display: flex;
  flex-direction: row;
}

.NavBarTopSessionBtn {
  background: none;
  border: none;
  border-right: .5px solid rgba($white1, .3);
  font-size: 1rem;
  padding: 10px 15px;
  color: $white1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  label {
    text-transform: capitalize;
  }

  i {
    margin-right: 5px;
  }

  span {
    border: 2px solid $white1;
    border-radius: 20rem;
    min-width: 2rem;
    width: 2rem;
    min-height: 2rem;
    height: 2rem;
    overflow: hidden;
    margin-right: 5px;
    object-fit: cover;
  }

  img {

  }

  @include mobile {
    font-size: 14px;


    span {
      min-width: 2rem;
      width: 2rem;
      min-height: 2rem;
      height: 2rem;
    }
  }
}

.NavBarTopSessionBtn:last-child {
  border-right: none;
}

.NavBarTopSessionBtn:hover {
  animation: fadeIn 1s;
  cursor: pointer;

  label {
    cursor: pointer;
  }
}

.NavBarTopSessionBtnMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1000 !important;
  background-color: $white1;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0 !important;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba($white5, .5);
}

.NavBarTopSessionBtnMenuItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
  border: none;
  border-left: 5px solid transparent;
  border-bottom: 1px solid $white3;
  margin: 0 !important;
  padding: 10px;
}

.NavBarTopSessionBtnMenuItem:active {
  color: $black1;
}

.NavBarTopSessionBtnMenuItem:hover {
  border-left: 5px solid $primary;
  cursor: pointer;

  label {
    cursor: pointer;
  }
}
