@import "../../theme/colors";
@import "../../theme/responsive_mixin";

.NavBarMenu {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $white1;
  padding: 10px 10rem;
  box-shadow: 0 2px 10px rgba($black5, .5);
  position: sticky;
  top: 0;
  z-index: 999 !important;
  transition: 500ms;
  margin-top: -20px;

  @include mobile {
    justify-content: space-between !important;
    padding: 10px 20px;
  }

  @include tablet {
    padding: 10px 20px;
  }
}

.NavBarMenuSecLogo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 5rem;
  -webkit-user-drag: none;
  user-select: none;
  opacity: .9;
  transition: 300ms;

  img {
    width: 100%;
  }

  @include mobile {
    img {
      width: 10rem;
    }
  }
}

.NavBarMenuSecLogo:hover {
  opacity: 1;
  cursor: pointer;
}

.NavBarMenuSecMenuBtn {
  display: none;
  font-size: 2rem;
  width: 4rem;
  height: 4rem;
  color: $primary;
  border-radius: 20rem;
  box-shadow: 0 2px 10px rgba($white5, .5);

  @include mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @include tablet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.NavBarMenuSecMenuBtn:hover {
  animation: fadeIn 1s;
}

.NavBarMenuSecMenu {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.NavBarMenuSecMenuResponsive {
  position: absolute;
  z-index: 0 !important;
  background-color: $white1;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.NavBarMenuSecMenuResponsive::before {
  position: absolute;
  z-index: -1 !important;
  background-color: rgba($black2, .8);
  width: 100vw;
  height: 100vh;
  content: "";
  opacity: .5;
  transition: 400ms;
  animation: fadeIn .5s both;
}

.NavBarMenuSecMenuItem {
  margin: 15px 15px;
  border: none;
  font-weight: bold;
  font-size: 1rem;
  color: $black1;
  position: relative;
  z-index: 1;
  transition: 400ms;

  @include mobile {
    margin: 0;
    padding: 15px 15px;
  }

  @include tablet {
    margin: 0;
    padding: 15px 15px;
  }
}

.NavBarMenuSecMenuItem:last-child {
  padding-bottom: 0;

  @include mobile {
    padding-bottom: 30px;
  }

  @include tablet {
    padding-bottom: 30px;
  }
}

.NavBarMenuSecMenuItem::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1 !important;
  background-color: $white1;
  width: 100%;
  height: 100%;
  content: "";
}


.NavBarMenuSecMenuItem:hover {
  cursor: pointer;
  color: $primary;

  label {
    cursor: pointer;
  }
}

.NavBarMenuSecMenuItemFocus {
  margin: 15px 15px;
  padding: 10px 15px;
  background-color: $white1;
  color: $primary;
  border: none;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 10px;
  transition: 400ms;

  label {
    cursor: pointer;
  }

  @include mobile {
    margin: 0;
    padding: 15px 15px;
    border-radius: 0;
  }

  @include tablet {
    margin: 0;
    padding: 15px 15px;
    border-radius: 0;
  }
}

.NavBarMenuSecMenuItemFocus::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1 !important;
  background-color: $white1;
  width: 100%;
  height: 100%;
  content: "";
}

.NavBarMenuSecMenuItemFocus:hover {
  cursor: pointer;
}

.NavBarMenuSecMenuItemPromo {
  margin: 15px 15px;
  padding: 10px 15px;
  background: $primary;
  color: $white1;
  border: none;
  font-weight: bold;
  font-size: 1rem;
  text-transform: capitalize;
  border-radius: 10px;

  label {
    cursor: pointer;
  }

  @include mobile {
    border-radius: 0;
  }
}

.NavBarMenuSecMenuItemPromo::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1 !important;
  background-color: $white1;
  width: 100%;
  height: 100%;
  content: "";
}

.NavBarMenuSecMenuItemPromo:hover {
  cursor: pointer;
}
