@import "../../../../../theme/colors";
@import "../../../../../theme/responsive_mixin";

.Section1Content {
  background-color: rgba($primary, .1);
  @include mobile {

  }
}

.Section1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;

  @include mobile {

  }
}

.Section1Title {
  position: relative;
  top: -10px;
  z-index: 2;
  text-align: center;
  color: $white1;
  background-color: $dark;
  margin: 0;
  padding: 1.5rem 10rem;
  width: 100%;
  font-weight: bold;
  transform: skewY(-5deg);

  h1 {
    transform: skewY(5deg);
    background-color: $primary;
    padding: 20px;
  }

  @include mobile {
    padding: 2rem 20px;
    transform: skewY(-10deg);

    h1 {
      transform: skewY(10deg);
    }
  }
}

.Section1Title::before {
  position: absolute;
  width: 100%;
  left: 0;
  transform: skewY(10deg);
  padding: 5rem;
  background-color: rgba($dark, .8);
  content: "";

  @include mobile {
    background-color: rgba($dark, .8);
    transform: skewY(20deg);
  }
}

.Section1Sec {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding: 5rem 10rem;
  width: 100%;
}

.Section1SecGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 20px;
  max-width: 300px;

  h2 {
    margin: 0;
    text-align: center;
    color: $black2;
  }

  p {
    text-align: center;
    font-weight: 300;
    font-size: 1.3rem;
    color: $black3;
  }
}
