@import "../../../../../../theme/colors";
@import "../../../../../../theme/responsive_mixin";

.CustomerSupportTrackingViewBanner1 {
  background-color: $white3;
  padding: 3rem 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    margin: 0;
    text-align: center;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    span {
      font-weight: 400;
      font-size: 1rem;
      margin-right: 10px;
    }
  }

  p {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $black3;

    label {
      margin: 0 10px;
      font-weight: 400;

      strong {
        margin-right: 5px;
      }
    }
  }

  @include mobile {
    padding: 3rem 20px;

    p {
      flex-direction: column;
    }
  }
}

.CustomerSupportTrackingViewBanner1Buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px 0 0;
  padding: 15px 20px;
  background-color: $white2;
  border-radius: 10px;
  box-shadow: inset 0 2px 5px rgba($black5, .5);

  @include mobile {
    flex-direction: column;
  }
}

.CustomerSupportTrackingViewBanner1LinkContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @include mobile {
    flex-direction: column;
  }
}

.CustomerSupportTrackingViewBanner1Link {
  padding: 10px 5px;
  color: $black3;
  transition: 400ms;
  border-right: 1px solid $white4;
  text-align: center;
  font-weight: bold;

  @include mobile {
    padding: 15px 5px;
    border-right: none;
    border-bottom: 1px solid $white4;
  }
}

.CustomerSupportTrackingViewBanner1Link:hover {
  color: $primary;
}


.CustomerSupportTrackingViewBanner1Link:last-child {
  border-right: none;
  @include mobile {
    border-bottom: none;
  }
}
