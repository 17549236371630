@import "../../theme/colors";
@import "../../theme/responsive_mixin";

.TextInputContent {
  position: relative;
  display: flex !important;
  flex-wrap: nowrap !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  margin-bottom: 10px;
  padding: 0 !important;

  input:read-only {
    background-color: $white2 !important;
  }
}

.TextInputContentSmall {
  width: 100px !important;
}

.TextInputContentNormal {
  width: 200px !important;
}

.TextInputContentFull {
  width: 85% !important;
}

.TextInputContentLabel {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 10px;
  color: $black4;

  @include mobile {
    font-size: 14px;
  }
}

%TextInput {
  z-index: 1;
  margin: 0 !important;
  background-color: $white1;
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 1rem;
}

.TextInput {
  @extend %TextInput;
  border: 1px solid $black5;
}

.TextInputError {
  @extend %TextInput;
  border: 1px solid #A60613;
}

.TextInputErrorContent {
  width: 80% !important;
  color: #A60613;
  padding: 5px 0 0;
  font-weight: bold;
  font-size: 10px;
  word-break: break-word;
}

.TextInputMaxLength {
  width: 100% !important;
  padding: 5px 0 0;
  font-weight: bold;
  font-size: 10px;
  word-break: break-word;
  text-align: right;
}

.TextInputContentRight {
  position: absolute;
  right: 5px;
  z-index: 100;
  padding: 12px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $black4;
  opacity: 1; /* Firefox */
}
