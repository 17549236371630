@import "../../theme/colors";
@import "../../theme/responsive_mixin";

.TextInputAutocompleteStack {
  position: relative;
}

.TextInputAutocompleteContent {
  position: relative;
  display: flex !important;
  flex-wrap: nowrap !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  margin-bottom: 10px;
  padding: 0 !important;
}

.TextInputAutocompleteContentSmall {
  width: 100px !important;
}

.TextInputAutocompleteContentNormal {
  width: 200px !important;
}

.TextInputAutocompleteContentFull {
  width: 82% !important;
}

.TextInputAutocompleteContentLabel {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 10px;
  color: $black4;

  @include mobile {
    font-size: 14px;
  }
}

%TextInputAutocomplete {
  z-index: 2;
  margin: 0 !important;
  background-color: $white1;
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 1rem;
}

.TextInputAutocomplete {
  @extend %TextInputAutocomplete;
  border: 1px solid $black5;
}

.TextInputAutocompleteError {
  @extend %TextInputAutocomplete;
  border: 1px solid #A60613;
}

.TextInputAutocompleteErrorContent {
  width: 80% !important;
  color: #A60613;
  padding: 5px 0 0;
  font-weight: bold;
  font-size: 10px;
  word-break: break-word;
}

.TextInputAutocompleteContentRight {
  position: absolute;
  right: 10px;
  z-index: 100;
  padding: 10px;
}

.TextInputAutocompleteRenderSmall {
  width: 100px !important;
}

.TextInputAutocompleteRenderNormal {
  width: 232px !important;
}

.TextInputAutocompleteRenderFull {
  width: 91% !important;
}

.TextInputAutocompleteRender {
  position: relative;
  z-index: 1;
  max-height: 200px;
  margin-top: -20px;
  background-color: $white1;
  box-shadow: 0 4px 10px rgba($black4, .4);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  overflow-y: auto;
}

%TextInputAutocompleteRenderItem {
  padding: 10px;
  user-select: none;
  font-weight: 400;
  font-size: 12px;
}

%TextInputAutocompleteRenderItem:first-child {
  padding-top: 20px;
}

.TextInputAutocompleteRenderItem {
  @extend %TextInputAutocompleteRenderItem;
}

.TextInputAutocompleteRenderItemFocus {
  @extend %TextInputAutocompleteRenderItem;
  background-color: rgba($dark, .7);
  color: $white1;
  cursor: pointer !important;
  font-weight: bold;
}

.TextInputAutocompleteRenderItem:hover {
  background-color: $white3;
  cursor: pointer !important;
  font-weight: bold;
}
