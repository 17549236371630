@import "../../../../../../theme/colors";
@import "../../../../../../theme/responsive_mixin";

.CustomerAppsLogViewSection1 {
  padding: 5rem 10rem;
  background-color: $white1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include mobile {
    padding: 5rem 20px;
  }
}

.CustomerAppsLogViewSection1Items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.CustomerAppsLogViewSection1Item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 10px 15px;
  padding: 10px 20px 0;
  background-color: $white2;
  border: none;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba($black5, .5);
  transition: 400ms;
  text-decoration: none;
}

.CustomerAppsLogViewSection1ItemImg {
  min-width: 10rem;
  width: 10rem;
  min-height: 10rem;
  height: 10rem;
  object-fit: cover;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}

.CustomerAppsLogViewSection1ItemTitle {
  font-weight: bold;
  font-size: 1.2rem;
  transition: 400ms;
  margin: 5px;
  color: $black1;
}

.CustomerAppsLogViewSection1ItemPackage{
  font-weight: 300;
  font-size: 1rem;
  color: $black3;
}

.CustomerAppsLogViewSection1ItemDescription{
  font-weight: 400;
  font-size: 1rem;
  color: $black4;
  border-radius: 20px;
  text-align: center;
}
