@import "../../../../../theme/colors";
@import "../../../../../theme/responsive_mixin";

.IndexAppAdminCouponSection3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16rem 10rem 5rem;
  margin: 0;
  background-color: rgba($primary, .1);
  position: relative;

  @include mobile {
    padding: 18rem 20px 5rem;
  }
}

.IndexAppAdminCouponSection3Title {
  position: absolute;
  top: -10px;
  z-index: 2;
  text-align: center;
  color: $white1;
  background-color: $dark;
  margin: 0;
  padding: 1.5rem 10rem;
  width: 100%;
  font-weight: bold;
  transform: skewY(-5deg);

  h1 {
    transform: skewY(5deg);
    background-color: $primary;
    padding: 20px;
  }

  @include mobile {
    padding: 2rem 20px;
    transform: skewY(-10deg);

    h1 {
      transform: skewY(10deg);
    }
  }
}

.IndexAppAdminCouponSection3Title::before {
  position: absolute;
  width: 100%;
  left: 0;
  transform: skewY(10deg);
  padding: 5rem;
  background-color: rgba($dark, .8);
  content: "";

  @include mobile {
    background-color: rgba($dark, .8);
    transform: skewY(20deg);
  }
}

.IndexAppAdminCouponSection3Sec {
  display: flex;
  flex-direction: column;
}

.IndexAppAdminCouponSection3SecGroupHeader {
  flex: 1;
  border: 1px solid $black4;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding: 5px 20px;
  transition: 400ms;

  h2 {
    text-align: left;
    font-size: 1.3rem;
    font-weight: 400;
    color: $black1;
  }

  span {
    font-size: 1.5rem;
  }

  @include mobile {
    flex-direction: column;
    justify-content: flex-start;

    h2 {
      text-align: center;
    }
  }
}

.IndexAppAdminCouponSection3SecGroupHeader:active {
  color: inherit;
}

.IndexAppAdminCouponSection3SecGroupHeader:hover {
  border: 1px solid $primary;
  cursor: pointer;
}

%IndexAppAdminCouponSection3SecGroup {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px 20px;
  overflow: hidden;
  transition: 600ms;

  p {
    margin: 0 0 20px;
    text-align: left;
    font-weight: 300;
    font-size: 1.2rem;
    color: $black3;
  }

  @include mobile {
    p {
     margin-bottom: 5rem;
    }
  }
}

.IndexAppAdminCouponSection3SecGroup {
  @extend %IndexAppAdminCouponSection3SecGroup;
  max-height: 0;
}

.IndexAppAdminCouponSection3SecGroupFocus {
  @extend %IndexAppAdminCouponSection3SecGroup;
  max-height: 40rem;

  @include mobile {
    max-height: 40vh;
    margin-bottom: 20px;
    padding-bottom: 5rem;
  }
}
