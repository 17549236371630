@import "../../../../../theme/colors";
@import "../../../../../theme/responsive_mixin";

.CustomerCompanyViewFile1 {
  border-top: 1px solid $white4;
  background-color: $white1;
  padding: 5rem 10rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @include mobile {
    padding: 5rem 20px;
    flex-direction: column;
  }
}


.CustomerCompanyViewFile1Sec {
  margin: 15px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  legend {
    font-weight: bold;
  }
}

.CustomerCompanyViewFile1SecImg {
  min-width: 10rem;
  width: 10rem;
  min-height: 10rem;
  height: 10rem;
  object-fit: cover;
  background-color: $primary;
  border-radius: 20rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}

.CustomerCompanyViewFile1SecImgRec {
  min-width: 10rem;
  width: 10rem;
  min-height: 8rem;
  height: 8rem;
  object-fit: cover;
  background-color: $white3;
  border-radius: 10px;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.CustomerCompanyViewFile1SecLink {
  padding: 20px 10px;
  color: $black3;
  transition: 400ms;
  text-align: center;
}

.CustomerCompanyViewFile1SecLink:hover {
  color: $primary;
}
