@import "../../../../../theme/colors";
@import "../../../../../theme/responsive_mixin";

.MenuApp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 10rem 0;
  background-color: $white1;

  @include mobile {
    display: none;
  }
}

.MenuAppOptions {
  display: flex;
  flex-direction: row;
  padding: 0;

  @include mobile {
    width: 100vw;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
  }
}

.MenuAppOptions::-webkit-scrollbar {
  background: transparent;
  border: none;
  width: 0;
  height: 0;
  transition: 500ms;
}

%MenuAppOptionsOption {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 1rem;
  margin: 10px;
  padding: 15px 40px;
  border-radius: 5px;
  font-weight: bold;
  transition: 300ms;

  i {
    font-size: 3.5rem;
    padding: 5px;
  }

  h6 {
    margin: 0;
  }
}

.MenuAppOptionsOption {
  @extend %MenuAppOptionsOption;
  background-color: $white1;
  color: $black4;
}

.MenuAppOptionsOption:hover {
  cursor: pointer !important;
  color: $primary;

  label {
    cursor: pointer !important;
  }
}

.MenuAppOptionsOptionFocus {
  @extend %MenuAppOptionsOption;
  background-color: $white3;
  color: $primary;
}
