@import "../../../theme/colors";

.IndexHome {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: white;
  overflow-x: hidden;
  overflow-y: auto;
}
