@import "../../theme/colors";
@import "../../theme/responsive_mixin";

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba($black4, .4);
}

.ModalGuide {
  position: fixed;
  top: 30vh;
  bottom: 0 !important;
  right: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap !important;
  justify-content: flex-end;
  align-items: flex-end;
  background: transparent;
  max-width: 400px;
  max-height: 80vh;
  min-width: 400px;

  .ModalContent {
    border-radius: 10px 0 0 0 !important;
    width: 100% !important;
    height: 100% !important;
    min-height: 90vh;

    @include mobile {
      min-height: 80vh;
    }
  }

  .ModalContentBody {
    margin-top: 20px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  @include mobile {
    max-width: 90vw;
    min-width: 90vw;
  }
}

%ModalContent {
  background-color: $white1;
  box-shadow: 0 2px 20px rgba($black5, .5);
  border-radius: 10px;
  min-width: 300px;
  min-height: 100px;
  max-width: 70vw;
  max-height: 60vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  //overflow-x: hidden;
  overflow-y: auto;
}

.ModalContentFullScreen {
  @extend %ModalContent;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
}

.ModalContent {
  @extend %ModalContent;

  @include mobile {
    .ModalContentBody {
      justify-content: center;
    }

    min-width: 80vw;
    min-height: 30vh;
    max-width: 90vw;
    max-height: 90vh;
  }
}

.ModalContentTitle {
  position: relative;
  z-index: 10;
  background-color: $white1;
  padding: 20px 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 10px rgba($white5, .5);

  .ModalContentTitle1 {
    font-weight: bold;
    text-transform: uppercase;
  }

  .ModalContentTitle2 {
    font-weight: 300;
  }
}

.ModalContentBody {
  flex: 1;
  background-color: $white2;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.ModalContentButtonLoading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
}

.ModalContentButton {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  padding: 10px 10px;
  border-top: .5px solid $white4;

  @include mobile{
    justify-content: space-between;
  }
}


.ModalContentButtonItem {

}

%ModalContentButtonItemContent {
  padding: 10px 20px;
  border-radius: 20rem;
  cursor: pointer;
}

.ModalContentButtonItemContent {
  @extend %ModalContentButtonItemContent;
  opacity: 1;
}


.ModalContentButtonItemContent:hover {
  background-color: rgba($primary, .1);
  color: $primary;
}

.ModalContentButtonItemContentDisabled {
  @extend %ModalContentButtonItemContent;
  opacity: .5;
  background-color: $white5;
  color: $black4;
  cursor: no-drop !important;

  .label {
    cursor: no-drop !important;
  }
}

