@import "../../../../../theme/colors";
@import "../../../../../theme/responsive_mixin";

.IndexAppPassengerBanner1 {
  flex: 1;
  min-height: 50vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: linear-gradient(to bottom, rgba($primary, .1), rgba($primary, .1), rgba($primary, .1));
  overflow: hidden;

  @include mobile {
    flex-direction: column;
    min-height: 65vh;
  }
}

.IndexAppPassengerBanner1Background {
  z-index: 1;
  width: 50vw;
  height: 100%;
  background-size: cover;
  background-position: -2rem 0;
  transition: 500ms;

  @include mobile {
    background-position: -2rem 0;
    background-size: cover;
    background-repeat: no-repeat;
    width: 140vw;
    min-height: 40vh;
  }
}

.IndexAppPassengerBanner1Sec {
  flex: 1;
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;

  div {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  div::after {
    content: "";
    position: absolute;
    background-image: linear-gradient(to bottom, rgba($primary, .8), rgba($dark, .5)), url('../../../../../assets/img/background_1.png');
    background-position: center;
    width: 150%;
    height: 100%;
    transform: skewX(15deg);
    left: -5.2rem;
  }

  h6 {
    position: relative;
    z-index: 2;
    margin: 0;
    color: $white1;
    font-size: 1.5rem;
    font-weight: 300;

  }

  h1 {
    position: relative;
    z-index: 2;
    margin: 0;
    color: $white1;
    font-size: 3rem;

  }

  @include mobile {
    div {
      padding: 20px 20px;
    }

    div::after {
      width: 120%;
    }

    h1 {
      margin-bottom: 20px;
    }
  }

  @include large {
    div::after {
      left: -10rem;
    }
  }
}

.IndexAppPassengerBanner1Sec::after {
  content: "";
  position: absolute;
  top: -10rem;
  right: -5rem;
  width: 120%;
  height: 100vh;
  transform: skewX(10deg);
  background-color: $primary;

  @include mobile {
    display: none;
  }
}
