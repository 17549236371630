$white1: #fff;
$white2: #f5f5f5;
$white3: #eee;
$white4: #ccc;
$white5: #b0b0b0;
$black1: #000;
$black2: #242424;
$black3: #333;
$black4: #666;
$black5: #999;
$light: #ffb87f;
$primary: #FF7601;
$dark: #d25e01;
