@import "../../../../../theme/colors";
@import "../../../../../theme/responsive_mixin";

.IndexAboutSection2 {
  padding: 5rem 10rem;
  background-color: $white1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;

  @include mobile {
    padding: 5rem 0 0;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  @include tablet {
    flex-wrap: wrap;
  }
}

.IndexAboutSection2Background {
  z-index: 1;
  width: 50vw;
  height: 70vh;
  transition: 500ms;
  overflow: hidden;
  border-radius: 20px;
  transform: translateX(40px);
  box-shadow: 0 2px 10px rgba($black4, .5);

  .IndexAboutSection2BackgroundImg {
    width: 100%;
    height: 100%;
    background-image: url('../../../../../assets/img/driver_banner_1.png');
    background-position: -5rem 0;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 20;
  }

  @include mobile {
    width: 80vw;
    height: 50vh;
    transform: translateX(-1px);

    .IndexAboutSection2BackgroundImg {
      background-position: -10rem 0;
      background-size: cover;
    }
  }

  @include tablet {
    width: 100vw;
  }
}

.IndexAboutSection2Background::after {
  content: "";
  background-color: rgba($dark, .5);
  position: absolute;
  z-index: 1;
  width: 40vw;
  height: 120%;
  border-radius: 20px;
  transform: translateX(-1rem) translateY(-28rem);

  @include mobile {
    width: 80vw;
    height: 50vh;
    transform: translateX(-1rem) translateY(-20rem);
  }
}

.IndexAboutSection2Background::before {
  content: "";
  background-color: rgba($primary, .5);
  position: absolute;
  z-index: 2;
  width: 40vw;
  height: 120%;
  border-radius: 20px;
  transform: translateX(0rem) translateY(-4rem);

  @include mobile {
    width: 80vw;
    height: 50vh;
    transform: translateX(1rem) translateY(-1rem);
  }
}

.IndexAboutSection2Sec {
  width: 50vw;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translateX(-5rem);

  h1 {
    color: $primary;
    text-align: center;
    font-weight: 400;
  }

  p {
    font-weight: 300;
    color: $black3;
    line-height: 1.5rem;
  }

  @include mobile {
    transform: translateX(0);
    width: 80%;

    h1 {
      margin-top: 3rem;
      font-size: 2rem;
    }

    p {
      font-size: 1.3rem;
      text-align: center;
    }
  }

  @include tablet {
    transform: translateX(0);
    width: 100vw;
    padding-left: 30px;

    h1 {
      margin-top: 3rem;
    }
  }
}
