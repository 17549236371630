@import "../../../../../theme/colors";
@import "../../../../../theme/responsive_mixin";

.Banner1 {
  flex: 1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 10rem;
  position: relative;
  background-image: linear-gradient(to bottom, rgba($primary, .1), rgba($primary, .1), rgba($primary, .1));


  img {
    z-index: 2;
    margin: 20px;
    width: 60vw;
  }

  h1 {
    z-index: 2;
    font-weight: bold;
    font-size: 2.5rem;
    word-wrap: break-word;
    text-align: center;
    color: $primary;
    text-shadow: 0 0 10px $white1;
  }

  p {
    z-index: 2;
    font-weight: 400;
    font-size: 2rem;
    margin: 0;
    padding: 0;
    word-wrap: break-word;
    text-align: center;
    color: $black1;
    text-shadow: 0 0 10px $white1;
  }

  @include mobile {
    padding: 0 20px;
    min-height: 75vh;

    h1 {
      font-size: 1.7rem;
      text-shadow: 2px 0 0 $white1, -2px 0 0 $white1, 0 2px 0 $white1, 0 -2px 0 $white1, 1px 1px $white1, -1px -1px 0 $white1, 1px -1px 0 $white1, -1px 1px 0 $white1;
    }

    img {
      width: 85vw;
    }

    p {
      font-size: 1.5rem;
      text-shadow: 2px 0 0 $white1, -2px 0 0 $white1, 0 2px 0 $white1, 0 -2px 0 $white1, 1px 1px $white1, -1px -1px 0 $white1, 1px -1px 0 $white1, -1px 1px 0 $white1;
    }
  }
}

.Banner1Content {
  position: relative;
  z-index: 100;
}

.Banner1Background {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba($primary, .01), rgba($dark, .01)), url('../../../../../assets/img/background_1.png');
  background-repeat: no-repeat;
  background-size: cover;
  opacity: .5;
}
