@import "../../../../../theme/colors";
@import "../../../../../theme/responsive_mixin";

.IndexFaqSection6 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 10rem;
  margin: 0;
  position: relative;
  background-color: $white1;

  @include mobile {
    padding: 3rem 20px;
  }
}

.IndexFaqSection6Title {
  text-align: center;
  color: $primary;
  padding: 0;
  width: 100%;
  font-weight: bold;

  h1 {
    padding: 20px;
    margin: 0 0 10px;
  }
}


.IndexFaqSection6Sec {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.IndexFaqSection6SecGroupHeader {
  width: 100%;
  border: 1px solid $black4;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding: 5px 20px;
  margin-bottom: 20px;
  transition: 400ms;

  h2 {
    text-align: left;
    font-size: 1.3rem;
    font-weight: 400;
    color: $black1;
  }

  span {
    font-size: 1.5rem;
  }

  @include mobile {
    flex-direction: column;
    justify-content: flex-start;

    h2 {
      text-align: center;
    }
  }
}

.IndexFaqSection6SecGroupHeader:active {
  color: inherit;
}

.IndexFaqSection6SecGroupHeader:hover {
  border: 1px solid $primary;
  cursor: pointer;
}

%IndexFaqSection6SecGroup {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: -10px 20px  10px;
  overflow: hidden;
  transition: 600ms;

  p {
    margin: 0 0 20px;
    text-align: left;
    font-weight: 300;
    font-size: 1.2rem;
    color: $black3;
  }

  span {
    font-weight: bold;
    cursor: pointer;
    transition: 400ms;
  }

  span:hover {
    color: $primary;
    cursor: pointer;
  }
}

.IndexFaqSection6SecGroup {
  @extend %IndexFaqSection6SecGroup;
}
