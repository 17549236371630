@import "../../../../../theme/colors";
@import "../../../../../theme/responsive_mixin";

.IndexAppPassengerBanner2 {
  background-color: $white1;
  padding: 5rem 10rem;

  @include mobile {
    padding: 3rem 20px;
  }
}

.IndexAppPassengerBanner2Title {
  flex: 1;
  width: 100%;
  padding: 0 20px;
  text-align: left;
  font-weight: bold;
  font-size: 2.3rem;

  @include mobile {
    text-align: center;
  }
}

.IndexAppPassengerBanner2Mobile {
  display: none;

  @include mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.IndexAppPassengerBanner2MobileSec{
  margin: 10px 0;
}

.IndexAppPassengerBanner2MobileImg {
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
}

.IndexAppPassengerBanner2Desktop {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 40px;

  @include mobile {
    display: none;
  }

  @include tablet {
    flex-wrap: wrap;
  }
}

.IndexAppPassengerBanner2DesktopSnapContent {
  display: flex;
  flex-direction: row;
  min-width: 20vw;
  max-width: 20vw;
  min-height: 50vh;
  overflow-x: hidden;
  overflow-y: hidden;
  transition-delay: 3s;
  position: relative;
  transition: 400ms;
}

.IndexAppPassengerBanner2DesktopSnapContentBackground {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 100% 100%;

  img {
    width: 100%;
    height: 100%;
  }
}

.IndexAppPassengerBanner2DesktopSec {
  position: relative;
  z-index: 1;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 1000ms;
  scroll-snap-align: start;

  img {
    width: 100%;
    height: 100%;
  }
}

.IndexAppPassengerBanner2DesktopSnapControl {
  display: flex;
  flex-direction: column;
  padding: 0;
}

%IndexAppPassengerBanner2DesktopSnapControlBtn {
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  transition: 400ms;
  opacity: .5;
  margin: 10px;

  section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 10px;
  }

  h6 {
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
    text-align: left;
  }

  label {
    margin-top: 5px;
    font-size: 1rem;
    font-weight: 300;
    text-align: left;
    color: $black3;
    line-height: 1.5rem;
  }

  @include mobile {
    opacity: 1;

    label {
      font-weight: 400;
      font-size: 1.2rem;
    }
  }
}

.IndexAppPassengerBanner2DesktopSnapControlBtn {
  @extend %IndexAppPassengerBanner2DesktopSnapControlBtn;
}

.IndexAppPassengerBanner2DesktopSnapControlBtn:hover {
  opacity: 1;
  cursor: pointer;

  label {
    cursor: pointer;
  }
}

.IndexAppPassengerBanner2DesktopSnapControlBtnFocus {
  @extend %IndexAppPassengerBanner2DesktopSnapControlBtn;
  opacity: 1;
}

%IndexAppPassengerBanner2DesktopSnapControlBtnSpan {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20rem;
  min-width: 2rem;
  min-height: 2rem;
  font-weight: bold;
  font-size: 1rem;
}

%IndexAppPassengerBanner2DesktopSnapControlBtnSpan:hover {
  cursor: pointer;
}

.IndexAppPassengerBanner2DesktopSnapControlBtnSpan {
  @extend %IndexAppPassengerBanner2DesktopSnapControlBtnSpan;
  background-color: transparent;
  color: $primary;
  border: 2px solid $primary;
}

.IndexAppPassengerBanner2DesktopSnapControlBtnSpanFocus {
  @extend %IndexAppPassengerBanner2DesktopSnapControlBtnSpan;
  background-color: $primary;
  color: $white1;
  border: 2px solid transparent;
}
