@import "../../../../../theme/colors";
@import "../../../../../theme/responsive_mixin";

.IndexContactSection1 {
  padding: 3rem 10rem;
  background-color: $white1;

  @include mobile {
    padding: 3rem 20px;
  }
}

.IndexContactSection1Title {
  margin-top: 4rem;
}

.IndexContactSection1Body {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.IndexContactSection1BodySec {
  margin-right: 2rem;
  display: flex;
  flex-direction: column;

  label {
    font-weight: 300;
    font-size: 1.2rem;
  }

  a {
    font-weight: bold;
    text-decoration: none;
    padding: 5px 0;
    color: $black1;
    transition: 500ms;
  }

  a:hover {
    cursor: pointer;
    color: $primary;
  }
}

.IndexContactSection1BodySec:last-child {
  margin-right: 0;
}
