@import "../../../../../theme/colors";
@import "../../../../../theme/responsive_mixin";

.IndexRegisterBanner1 {
  position: relative;
  background-color: white;
  padding: 5rem 10rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @include mobile {
    padding: 5rem 20px;
  }
}

.IndexRegisterBanner1Background {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba($dark, .8), rgba($primary, .5), rgba($dark, .8)), url('../../../../../assets/img/background_1.png');
  background-size: cover;
  opacity: .5;
  top: 0;
  left: 0;
}

.IndexRegisterBanner1Sec {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    text-align: center;
    font-weight: 400;
    font-size: 2rem;
    margin: 0;
    color: $black1;
  }

  p {
    color: $black2;
    font-weight: 400;
    font-size: 1.3rem;
    text-align: center;
  }

  button {
    background-color: $primary;
    font-size: 1.5rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    padding: 20px 40px;
    color: $white1;
  }

  button:hover {
    cursor: pointer;

    label {
      cursor: pointer;
    }
  }

  @include mobile {

    h1 {
    }

    p {
      font-size: 1.5rem;
    }

    button {
      font-size: 1.2rem;
    }
  }
}
